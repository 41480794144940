@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.css";

a {
  cursor: pointer;
}

body {
  background-color: rgb(247 248 250);
}

.hide-spin::-webkit-inner-spin-button,
.hide-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
